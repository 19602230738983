import cls from '@/helpers/cls';
import { HTMLAttributes } from 'react';

export default function FieldGroup({ className = '', children, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cls('flex flex-col gap-0.5 font-forms', className)} {...props}>
      {children}
    </div>
  );
}
