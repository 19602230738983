import { SVGAttributes } from 'react';

export default function ArrowIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
      <g>
        <circle cx="15" cy="15" r="15" className="fill-accent" />
        <path
          d="M13.3 21.5a1.08 1.08 0 0 1-.7-.3.967.967 0 0 1 0-1.4l4.8-4.8-4.8-4.8A.99.99 0 0 1 14 8.8l5.5 5.5a.967.967 0 0 1 0 1.4L14 21.2a.908.908 0 0 1-.7.3Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
