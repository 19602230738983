import ArrowIcon from '@/Components/Icons/ArrowIcon';
import cls from '@/helpers/cls';
import { ButtonHTMLAttributes } from 'react';

export default function PrimaryAuthButton({ className = '', disabled, children, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className={cls(
        'group relative flex w-auto items-center justify-center gap-2 font-forms text-lg font-medium focus:outline-none',
        disabled && 'opacity-25',
        className,
      )}
      disabled={disabled}
    >
      {children}
      <ArrowIcon className="h-7 transition-transform ease-in-out group-hover:translate-x-2 group-focus:translate-x-2" />
      <div className="duration-250 absolute -bottom-1.5 -top-1.5 left-1/2 right-1/2 scale-95 border-y border-y-accent transition-all group-focus:-left-1.5 group-focus:-right-1.5 group-focus:scale-100"></div>
    </button>
  );
}
